var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',{staticClass:"px-2"},[_c('v-subheader',{staticClass:"px-0"},[_c('b',[_vm._v("Teléfonos Proveedor")]),(_vm.showFormActions)?[_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary","rounded":"","small":""},on:{"click":function($event){return _vm.agregarTelefono()}}},[_vm._v("Agregar Teléfono")])]:_vm._e()],2),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"formTelefonos",attrs:{"readonly":_vm.isFormReadonly}},[_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headersTelefonos,"items":_vm.$store.state.entities.lstTelefonos,"item-key":"index"},scopedSlots:_vm._u([{key:"item.id_tipotelefono",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{attrs:{"items":_vm.$store.state.masterusuarios.lstTipoTelefono,"item-text":"descripcion","item-value":"id","rules":[function (v) { return !!v || 'Dato Requerido'; }]},model:{value:(item.id_tipotelefono),callback:function ($$v) {_vm.$set(item, "id_tipotelefono", $$v)},expression:"item.id_tipotelefono"}})]}},{key:"item.nro_telefono",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"prefix":"+","rules":[
                function (v) { return !!v || 'Dato Requerido'; },
                function (v) { return /^\d+$/.test(v) || 'Formato de teléfono incorrecto'; } ]},model:{value:(item.telefono),callback:function ($$v) {_vm.$set(item, "telefono", $$v)},expression:"item.telefono"}})]}},(_vm.showFormActions)?{key:"item.action",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.eliminarTelefono(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}:null],null,true)})],1)],1)],1),_c('br'),_c('v-subheader',{staticClass:"px-0"},[_c('b',[_vm._v("Contactos")]),(_vm.showFormActions)?[_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary","rounded":"","small":""},on:{"click":function($event){return _vm.agregarContacto()}}},[_vm._v("Agregar Contacto")])]:_vm._e()],2),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"formContactos",attrs:{"readonly":_vm.isFormReadonly}},[_c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headersContactos,"items":_vm.$store.state.entities.lstContactos,"item-key":"index"},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
              var item = ref.item;
return [_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || 'Dato Requerido'; }],"error-messages":item.errornombre},model:{value:(item.nombre),callback:function ($$v) {_vm.$set(item, "nombre", $$v)},expression:"item.nombre"}})]}},{key:"item.id_tipotelefono",fn:function(ref){
              var item = ref.item;
return [_c('v-autocomplete',{attrs:{"items":_vm.$store.state.masterusuarios.lstTipoTelefono,"item-text":"descripcion","item-value":"id","rules":[function (v) { return !!v || 'Dato Requerido'; }],"error-messages":item.errorTipoTelf},model:{value:(item.id_tipotelefono),callback:function ($$v) {_vm.$set(item, "id_tipotelefono", $$v)},expression:"item.id_tipotelefono"}})]}},{key:"item.nro_telefono",fn:function(ref){
              var item = ref.item;
return [_c('v-text-field',{attrs:{"prefix":"+","rules":[
                function (v) { return !!v || 'Dato Requerido'; },
                function (v) { return /^\d+$/.test(v) || 'Formato de teléfono incorrecto'; } ],"error-messages":item.errorTelefono},model:{value:(item.telefono),callback:function ($$v) {_vm.$set(item, "telefono", $$v)},expression:"item.telefono"}})]}},(_vm.showFormActions)?{key:"item.action",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.eliminarContacto(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}:null],null,true)})],1)],1)],1)],1),(_vm.showNavigationButtons)?_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.$store.state.entities.stepper--}}},[_vm._v("Regresar")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary"},on:{"click":_vm.validarFormulario}},[_vm._v("Continuar")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }